import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCheck } from '@fortawesome/free-solid-svg-icons'

const CodeDetectPlan = ({ loginStatus }) => {
  return (
    <>
      {/* <!-- Process --> */}
      <section className='relative dark:bg-jacarta-800'>
        {/* <picture className="pointer-events-none absolute inset-0 -z-10 dark:hidden">
                    <img src="/images/gradient_light.jpg" alt="gradient" className="h-full w-full" />
                </picture> */}
        <div className='container'>
          <div className='grid grid-cols-1 gap-8 md:grid-cols-2 lg:grid-cols-3'>
            <div className='relative rounded-2.5xl border border-jacarta-100 bg-white p-10 shadow-[0_5px_0_0_#8358ff] transition-shadow hover:shadow-[0_16px_24px_-8px_rgba(131,88,255,.3)] dark:border-jacarta-700 dark:bg-jacarta-700'>
              <svg
                xmlns='http://www.w3.org/2000/svg'
                viewBox='0 0 24 24'
                width='24'
                height='24'
                className='absolute right-3 top-3 h-16 w-16 fill-jacarta-50 dark:fill-jacarta-600'
              >
                <path fill='none' d='M0 0h24v24H0z' />
                <path d='M22 6h-7a6 6 0 1 0 0 12h7v2a1 1 0 0 1-1 1H3a1 1 0 0 1-1-1V4a1 1 0 0 1 1-1h18a1 1 0 0 1 1 1v2zm-7 2h8v8h-8a4 4 0 1 1 0-8zm0 3v2h3v-2h-3z' />
              </svg>
              <div className='mb-6 inline-flex items-center justify-center'>
                <p className='text-4xl font-display text-[#8358ff]'>
                  Code Essential
                </p>
              </div>
              <h3 className='mb-8 font-display text-lg text-jacarta-700 dark:text-white'>
                $16 / month
              </h3>
              <ul className='ml-6 mb-8 dark:text-jacarta-300 list-disc space-y-1'>
                <li>200,000 tokens per month</li>
                <li>Unlock model specific AI models</li>
                <li>
                  Unlock IDE plugins
                  <span className='ml-1 rounded inline bg-accent py-1 px-2 text-tiny font-bold uppercase text-white'>
                    Coming soon
                  </span>
                </li>
              </ul>
              {!loginStatus ? (
                <a className='absolute left-10 bottom-6 w-36 rounded-full bg-accent py-3 px-8 text-center font-semibold text-white shadow-accent-volume transition-all hover:bg-accent-dark cursor-pointer'>
                  Get plan
                </a>
              ) : (
                <div className='font-display absolute left-10 bottom-6 w-36'>
                  <FontAwesomeIcon
                    icon={faCheck}
                    className=' w-4 mr-4 inline'
                  />
                  Plan activated
                </div>
              )}
            </div>
            <div className='relative rounded-2.5xl border border-jacarta-100 bg-white p-10 shadow-[0_5px_0_0_#10b981] transition-shadow hover:shadow-[0_16px_24px_-8px_rgba(16,185,129,.3)] dark:border-jacarta-700 dark:bg-jacarta-700'>
              <svg
                xmlns='http://www.w3.org/2000/svg'
                viewBox='0 0 24 24'
                width='24'
                height='24'
                className='absolute right-3 top-3 h-16 w-16 fill-jacarta-50 dark:fill-jacarta-600'
              >
                <path fill='none' d='M0 0h24v24H0z' />
                <path d='M3 13h8V3H3v10zm0 8h8v-6H3v6zm10 0h8V11h-8v10zm0-18v6h8V3h-8z' />
              </svg>
              <div className='mb-6 inline-flex items-center justify-center'>
                <p className='text-4xl font-display text-[#10b981]'>
                  Code Premium
                </p>
              </div>
              <h3 className='mb-8 font-display text-lg text-jacarta-700 dark:text-white'>
                $30 / month
              </h3>
              <ul className='ml-6 mb-10 dark:text-jacarta-300 list-disc space-y-1'>
                <li>Everything in essential</li>
                <li>Unlimited tokens per month</li>
                <li>All model selections</li>
                <li>Compare your content to potential models!</li>
              </ul>

              {!loginStatus ? (
                <a className='absolute left-10 bottom-6 w-36 rounded-full bg-accent py-3 px-8 text-center font-semibold text-white shadow-accent-volume transition-all hover:bg-accent-dark cursor-pointer'>
                  Get plan
                </a>
              ) : (
                <div className='font-display absolute left-10 bottom-6 w-36'>
                  <FontAwesomeIcon
                    icon={faCheck}
                    className=' w-4 mr-4 inline'
                  />
                  Plan activated
                </div>
              )}
            </div>
            <div className='relative rounded-2.5xl border border-jacarta-100 bg-white p-10 shadow-[0_5px_0_0_#ef4444] transition-shadow hover:shadow-[0_16px_24px_-8px_rgba(239,68,68,.3)] dark:border-jacarta-700 dark:bg-jacarta-700'>
              <svg
                xmlns='http://www.w3.org/2000/svg'
                viewBox='0 0 24 24'
                width='24'
                height='24'
                className='absolute right-3 top-3 h-16 w-16 fill-jacarta-50 dark:fill-jacarta-600'
              >
                <path fill='none' d='M0 0h24v24H0z' />
                <path d='M10.9 2.1l9.899 1.415 1.414 9.9-9.192 9.192a1 1 0 0 1-1.414 0l-9.9-9.9a1 1 0 0 1 0-1.414L10.9 2.1zm2.828 8.486a2 2 0 1 0 2.828-2.829 2 2 0 0 0-2.828 2.829z' />
              </svg>
              <div className='mb-6 inline-flex items-center justify-center'>
                <p className='text-4xl font-display text-[#ef4444]'>
                  Code Enterprise
                </p>
              </div>
              <h3 className='mb-4 font-display text-lg text-jacarta-700 dark:text-white'>
                Contact us
              </h3>
              <ul className='ml-6 mb-10 dark:text-jacarta-300 list-disc space-y-1'>
                <li>
                  Personalized approach for large-scale or specialized needs.
                </li>
                <li>
                  No fixed price - packages crafted to align with your strategic
                  goals.
                </li>
                <li>
                  Pay for exactly what you need, ensuring value and alignment
                  with business objectives.
                </li>
              </ul>
            </div>
          </div>
        </div>
      </section>
      {/* <!-- end process --> */}
    </>
  )
}

export default CodeDetectPlan
