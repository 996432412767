import React, { useState, useContext } from 'react'
import HeadLine from '../headLine'
import AssemblePlan from './plan_assemble'

const AllInOnePlan = ({ loginStatus }) => {
  const [tabIndex, setTabIndex] = useState(0)
  const [itemActive, setItemActive] = useState(1)

  const handleSwitechTab = index => {
    console.log(index)
    if (index === 3 || index === 4) {
      return
    } else {
      setItemActive(index)
    }
  }

  return (
    <>
      {/* <!-- Process --> */}
      <div className='py-24'>
        <HeadLine
          text='Unlock Full Potential, and Explore Every Tool in Our Suite!'
          classes='font-display text-jacarta-700 mb-16 text-center text-3xl dark:text-white'
        />
        <AssemblePlan loginStatus={loginStatus} />
      </div>
      {/* <!-- end process --> */}
    </>
  )
}

export default AllInOnePlan
