import React, { useState, useContext } from 'react'
import NaturalLanguagePlan from './naturallanguageplan'
import CodeDetectPlan from './codedetectplan'
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs'
import HeadLine from '../headLine'

const SpecificPlans = ({ loginStatus }) => {
  const [tabIndex, setTabIndex] = useState(0)
  const [itemActive, setItemActive] = useState(1)

  const tabItem = [
    {
      id: 1,
      text: 'Natural Language',
      disabled: false,
    },
    {
      id: 2,
      text: 'Code Detection',
      disabled: false,
    },
    {
      id: 3,
      text: 'Image Analysis',
      disabled: false,
    },
    {
      id: 4,
      text: 'Writing Assistant',
      disabled: false,
    },
  ]

  const handleSwitchTab = index => {
    setItemActive(index)
  }

  return (
    <>
      {/* <!-- Process --> */}
      <div className='py-24'>
        <HeadLine
          text='...or Choose the Tool That Fits Your Needs!'
          classes='font-display text-jacarta-700 mb-16 text-center text-3xl dark:text-white'
        />
        <Tabs
          className='tabs '
          selectedIndex={tabIndex}
          onSelect={index => setTabIndex(index)}
        >
          <TabList className='no-scrollbar nav nav-tabs scrollbar-custom dark:border-jacarta-600 border-jacarta-100 mb-12 flex items-center justify-start overflow-x-auto overflow-y-hidden border-b pb-px md:justify-center'>
            {tabItem.map(({ id, text, disabled }) => {
              return (
                <Tab
                  className={`cursor-pointer nav-item`}
                  key={id}
                  onClick={() => handleSwitchTab(id)}
                >
                  <button
                    className={
                      itemActive === id
                        ? 'nav-link active hover:text-jacarta-700 text-jacarta-400 relative flex items-center whitespace-nowrap py-3 px-6 dark:hover:text-white'
                        : 'nav-link hover:text-jacarta-700 text-jacarta-400 relative flex items-center whitespace-nowrap py-3 px-6 dark:hover:text-white'
                    }
                  >
                    <span className='font-display text-xl font-medium'>
                      {text}
                    </span>
                  </button>
                  {disabled && (
                    <span className='absolute rounded inline bg-accent py-1 px-2 text-tiny font-bold uppercase text-white ml-6'>
                      Coming soon
                    </span>
                  )}
                </Tab>
              )
            })}
          </TabList>
          <TabPanel>
            <NaturalLanguagePlan loginStatus={loginStatus} />
          </TabPanel>
          <TabPanel>
            <CodeDetectPlan loginStatus={loginStatus} />
          </TabPanel>
          <TabPanel>
            <NaturalLanguagePlan loginStatus={loginStatus} />
          </TabPanel>
          <TabPanel>
            <CodeDetectPlan loginStatus={loginStatus} />
          </TabPanel>
        </Tabs>
      </div>
      {/* <!-- end process --> */}
    </>
  )
}

export default SpecificPlans
